.heading {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  /* identical to box height */

  color: #ffffff;
}

.normal {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  margin-left: 10px;
  text-align: right;

  color: #ffffff;
}

.back {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #ffffff;
}

.security {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
}

.back-navidation {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}

.inactive-user {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: white;
}
