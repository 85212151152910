.back-navidation {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff!important;
}

.form-text {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  letter-spacing: -0.408px;

  color: #ffffff!important;
}

.form-input {
  height: 50px;
  border-radius: 14px;
}

.form-input-datepicker {
  background: #17314c !important;
  border: none;
  border-radius: 10px;
  height: 50px;
}

.ant-select-selector {
  border: none !important;
  border-radius: 14px !important;
  height: 50px !important;
  align-items: center;
  color: white;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.ant-picker-input > input {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}

.ant-picker.ant-picker-borderless {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
  background-color: #17314c !important;
}

.primary-select {
  background-color: #17314c;
  height: 50px !important;
  border-radius: 10px;
}
.ant-select-selection-item {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* color: #ffffff; */
}

.ant-select-selection-placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* color: #ffffff; */
}

.ant-form-item-label > label {
  color: white;
}

.ant-input {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px !important;
  line-height: 24px;

  /* color: white !important; */
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  font-family: "SF Pro Display" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 24px !important;

  color: #ffffff !important;
  background: #17314c !important;
}
.ant-input::placeholder {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;

  color: white !important;
}
