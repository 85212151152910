@import "~antd/dist/antd.css";

.header-text {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 188% */

  letter-spacing: -0.556364px;
  background: none;
  color: #ffffff;
}

.ant-layout-header {
  padding: 0px;
}

.ant-input-prefix {
  margin-left: 40px;
  margin-right: 20px;
}

.ant-btn,
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  border: none;
  color: #ffffff;

  background: rgba(36, 33, 47, 0.2);
  box-shadow: 5.537px 4.4296px 11.074px rgba(0, 0, 0, 0.25),
    inset 1.1074px 1.1074px 0px rgba(255, 255, 255, 0.25);
}
.ant-btn-primary {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  border: none;
  color: #ffffff;

  background: rgba(36, 33, 47, 0.9);
  box-shadow: 5.537px 4.4296px 11.074px rgba(0, 0, 0, 0.25),
    inset 1.1074px 1.1074px 0px rgba(255, 255, 255, 0.25);
}

.ant-menu-item-selected {
  font-weight: 700;
  color: rgb(255, 255, 255) !important;
  border-bottom: none !important;
}

.ant-menu-item:hover {
  color: rgb(255, 255, 255) !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #ffffff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid rgb(255, 255, 255);
}

.ant-input {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 19.897px !important;
  line-height: 24px;
  color: white !important;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  font-family: "SF Pro Display" !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 19.897px !important;
  line-height: 24px !important;

  color: #ffffff !important;
  background: #17314c !important;
}
.ant-input::placeholder {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 19.897px;
  line-height: 24px;

  color: white !important;
}

/* This  is really bad */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text !important;
  -webkit-box-shadow: 0 0 0px 1000px #17314c inset;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
} */

.primary-button {
  box-sizing: border-box;
  color: white;

  border: none;
  background: rgba(36, 33, 47, 0.2);
  box-shadow: 5.537px 4.4296px 11.074px rgba(0, 0, 0, 0.25),
    inset 1.1074px 1.1074px 0px rgba(255, 255, 255, 0.25);
  border-radius: 50px;
}

.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-thead > tr > th {
  background: #0d1927;
  color: white;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* or 120% */

  text-align: center;
  letter-spacing: 0.11505px;
  border-radius: 0px;
  border: none;
}

tr:nth-child(odd) {
  background: #13283e;
  color: rgb(255, 255, 255);
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* or 120% */

  text-align: center;
  letter-spacing: 0.11505px;

  color: #ffffff;
  border: none;
}

tr:nth-child(even) {
  background: #17314c;
  color: rgb(255, 255, 255);
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  /* or 120% */

  text-align: center;
  letter-spacing: 0.11505px;
  border: none;
}

.main-heading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  /* identical to box height, or 107% */

  letter-spacing: -0.4776px;

  color: #ffffff;
}

.ant-result-title {
  color: rgb(255, 255, 255) !important;
}

.ant-result-subtitle {
  color: rgb(255, 255, 255) !important;
}
