.ant-picker-suffix {
  color: white;
}

.ant-picker-content thead tr th {
  background: white;
}

.ant-picker-content tbody tr {
  background: white;
}

.ant-picker-content tbody tr:hover {
  background: white;
}

.ant-picker-content thead tr th:hover {
  background: white;
}
