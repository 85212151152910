.signin-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0e1e2e;
}

.title-login {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 29.8731px;
  line-height: 36px;
  /* or 121% */

  text-align: center;
  letter-spacing: 0.328604px;

  /* Label/Dark/Primary */

  color: #ffffff;
}

.input-login {
  height: 70px;
  border-radius: 14px;
}
