.investment-quantity {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 200;
  font-size: 74.9833px;
  line-height: 59px;
  /* or 79% */

  letter-spacing: 0.315496px;

  color: #ffffff;
}

.investment-currency {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18.8493px;
  line-height: 50px;
  /* identical to box height, or 266% */

  letter-spacing: 0.457318px;
  text-transform: uppercase;
  color: #ffffff;
}

.investment-name {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.investment-heading {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 300;
  font-size: 17.1387px;
  line-height: 24px;
  /* identical to box height, or 138% */

  letter-spacing: -0.102832px;

  color: #ffffff;
}

.investment-normal {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */

  color: #ffffff;
}
.delete-this-record {
  margin-left: 10px;
  font-family: "SF Pro Text";
  font-style: normal;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #ffffff;
}
.secondary-button {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  border: none;
  background: #f5f5f5;
  /* identical to box height */
}
.secondary-button:hover {
  background: #f5f5f5;
  color: #272727;
}

.fireblock-select {
  background-color: #17314c;
  height: "30px";
  border-radius: 10px;
}

.ant-select-selection-search-input {
  height: 50px;
}
