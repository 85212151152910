.normal-text {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.normal-heading {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.info {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 151.34%;
  /* or 24px */

  color: #ffffff;
}

.white-button {
  border: solid;
  border-width: 1px;
  border-color: "white";
  border-radius: 87.3494px;
  background: none;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.ant-input-password-icon.anticon {
  color: white;
}
